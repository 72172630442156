import { createApp } from 'vue'
import "./assets/iconfont.css";
import "./assets/iconfont.js";
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/font/font.css'
import request from '../src/http/http'
import 'default-passive-events'

import { ElMessage } from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import { zhCn } from 'element-plus/es/locale';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app =createApp(App).use(store).use(router)
app.use(ElementPlus, {
  locale:zhCn
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  app.config.globalProperties.$http=request
  app.config.globalProperties.$message = ElMessage
  app.mount('#app')
