import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path:'/',
    redirect: '/login'
  },
  {
    path:'/login',
    name:'Login',
    component:() =>import('../components/Login/login.vue')
  },
  {
    path:'/Home',
    name:'Home',
    component: Home,
    children:[
      // 首页
      {
        path:'/homePage',
        name:'homePage',
        component:() =>import('../views/homePage.vue')
      },
      // 患者管理
      {
        path:'/patientManage',
        name:'patientManage',
        component:() =>import('../components/PatientManage/patientManage.vue')
      },
      // 添加患者
      {
        path:'/AddPatient',
        name:'AddPatient',
        component:() =>import('../components/PatientManage/AddPatient.vue')
      },
      // 实时监控
      {
        path:'/RealTimeMonitor',
        name:'RealTimeMonitor',
        component:() =>import('../components/PatientManage/RealTimeMonitor.vue')
      },
       // 实时监控
       {
        path:'/RealTimeMonitorZZ',
        name:'RealTimeMonitorZZ',
        component:() =>import('../components/PatientManage/RealTimeMonitorZZ.vue')
      },
      // 事件记录
      {
        path:'/EventRecord',
        name:'EventRecord',
        component:() =>import('../components/PatientManage/EventRecord.vue')
      },
      // 修改事件记录
      {
        path:'/editEventRecord',
        name:'editEventRecord',
        component:() =>import('../components/PatientManage/Event/editEventRecord.vue')
      },
       // 新增事件记录
       {
        path:'/AddEventRecord',
        name:'AddEventRecord',
        component:() =>import('../components/PatientManage/Event/AddEventRecord.vue')
      },
      // 患者信息
      {
        path:'/patientMessage',
        name:'patientMessage',
        component:() =>import('../components/PatientManage/patientMessage.vue')
      },
      // 个人设置
      {
        path:'/PersonalSettings',
        name:'PersonalSettings',
        component:() =>import('../components/PatientManage/PersonalSettings.vue')
      },

      //设备管理
      {
        path:'/EquipManage',
        name:'EquipManage',
        component:() =>import('../components/EquipManage/EquipManage.vue')
      },
      //报警设置
      {
        path:'/AlarmSettings',
        name:'AlarmSettings',
        component:() =>import('../components/Settings/AlarmSettings.vue')
      },
      {
        path:'/AboutUs',
        name:'AboutUs',
        component:() =>import('../components/Settings/AboutUs.vue')
      },
      {
        path:'/printpropare',
        name:'printpropare',
        component:() =>import('../components/print/printpropare.vue')
      },
      {
        path:'/DocManage',
        name:'DocManage',
        component:() =>import('../components/DocManage/DocManage.vue')
      },
      {
        path:'/ReleaseRecords',
        name:'ReleaseRecords',
        component:() =>import('../components/Event/alarmRecord.vue')
      },
    ]
  },
  {
    path:'/printPDF',
    name:'printPDF',
    component:() =>import('../components/print/printPDF.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
