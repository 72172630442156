import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import { ElNotification } from 'element-plus'
import router from '../router/index'
export default createStore({
  state: {
    user: null, // 用户信息
    patienlist: <any>[],// 初始化一个空数组来存储个人信息
    patienListOld: <any>[],
    alertsShow: <any>[],// 初始化一个空数组来存储报警信息
    alertsShowNew: <any>[], // 初始化一个空数组来存储报警信息
    alertShowSave: <any>[]//初始化一个空数组来保存保存下来的报警信息
  },
  getters: {
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    // 添加报警
    ADD_ALERT(state, alert: any) {

      const index = state.patienlist.findIndex((item) => item.id == alert.id)
      if (index != -1) {
        state.patienlist.splice(index, 1, { ...alert })
      } else {
        state.patienlist.push(alert);
      }

      for (let i = 0; i < state.patienlist.length; i++) {
        //找到一样的
        const index = state.patienListOld.findIndex((item) => item.id == state.patienlist[i].id)
        if (index != -1) {
          let type = state.patienListOld[index].type
          let time = state.patienListOld[index].time
      
          let typeArr = ['血糖偏高', '血糖偏低', '低血糖', '待校准']
          if (typeArr.indexOf(state.patienlist[i].type) != -1) {
            // 一种是发生了变化，一种是超过30min

            const index2 = state.alertsShowNew.findIndex((item) => item.id == state.patienlist[i].id)
            if (index2 != -1) {
              if (state.patienlist[i].type != type || state.patienlist[i].time - time > (60 * 30)) {
                
                // push进去
                let tip = state.patienlist[i].ward + '  ' + state.patienlist[i].bed + '  ' + state.patienlist[i].name + ' ' + state.patienlist[i].type
                const notification = ElNotification.warning({
                  title: state.patienlist[i].type,
                  message: tip,
                  showClose: true,
                  position: 'bottom-right',
                  offset: 0,
                  duration: 1000 * 60 * 30,
                  customClass: 'custom-notification',
                  onClick: function () {
                    let patienList = JSON.parse(sessionStorage.getItem('patienList') || '')
                    let patientData
                    for (let j = 0; j < patienList.length; j++) {
                      if (patienList[j].id == state.patienlist[i].id) {
                        patientData = patienList[j]
                        sessionStorage.setItem('patient_id', patientData.id)
                        sessionStorage.setItem('patientName', patientData.patient_info.name)
                        sessionStorage.setItem('status', patientData.state)
                        sessionStorage.setItem('serial_number', patientData.serial_number)
                        sessionStorage.setItem('department_id', patientData.department_id)
                        sessionStorage.setItem('delFlag', '1')
                        notification.close()
                        router.push({
                          name: 'RealTimeMonitorZZ',
                          state: {
                            data:
                              JSON.stringify(patientData)
                          }
                        })
                        return
                      }

                    }

                  }
                })
                state.alertsShow.unshift(state.patienlist[i])
                state.alertShowSave.unshift(state.patienlist[i])
                state.alertsShowNew[index2] = state.patienlist[i]
              }
            } else {
              let tip = state.patienlist[i].ward + '  ' + state.patienlist[i].bed + '  ' + state.patienlist[i].name + ' ' + state.patienlist[i].type
              const notification = ElNotification.warning({
                title: state.patienlist[i].type,
                message: tip,
                showClose: true,
                position: 'bottom-right',
                offset: 0,
                duration: 1000 * 60 * 30,
                customClass: 'custom-notification',
                onClick: function () {
                  let patienList = JSON.parse(sessionStorage.getItem('patienList') || '')
                  let patientData
                  for (let j = 0; j < patienList.length; j++) {
                    if (patienList[j].id == state.patienlist[i].id) {
                      patientData = patienList[j]
                      sessionStorage.setItem('patient_id', patientData.id)
                      sessionStorage.setItem('patientName', patientData.patient_info.name)
                      sessionStorage.setItem('status', patientData.state)
                      sessionStorage.setItem('serial_number', patientData.serial_number)
                      sessionStorage.setItem('department_id', patientData.department_id)
                      sessionStorage.setItem('delFlag', '1')
                      notification.close()
                      router.push({
                        name: 'RealTimeMonitorZZ',
                        state: {
                          data:
                            JSON.stringify(patientData)
                        }
                      })
                      return
                    }

                  }

                }
              })
              state.alertsShow.unshift(state.patienlist[i])
              state.alertShowSave.unshift(state.patienlist[i])
              state.alertsShowNew.unshift(state.patienlist[i])
            }

          } else {
            const index2 = state.alertsShowNew.findIndex((item) => item.id == state.patienlist[i].id)
            if (index2 != -1) {
              state.alertsShowNew.splice(index2, 1);
            }
          }
        } else {
          let typeArr = ['血糖偏高', '血糖偏低', '低血糖', '待校准']
          if (typeArr.indexOf(state.patienlist[i].type) != -1) {
            let tip = state.patienlist[i].ward + '  ' + state.patienlist[i].bed + '  ' + state.patienlist[i].name + ' ' + state.patienlist[i].type
            const notification1 = ElNotification.warning({
              title: state.patienlist[i].type,
              message: tip,
              showClose: true,
              offset: 0,
              position: 'bottom-right',
              customClass: 'custom-notification',
              duration: 1000 * 60 * 30,
              onClick: function () {

                let patienList = JSON.parse(sessionStorage.getItem('patienList') || '')
                let patientData
                for (let j = 0; j < patienList.length; j++) {
                  if (patienList[j].id == state.patienlist[i].id) {
                    patientData = patienList[j]
                    sessionStorage.setItem('patient_id', patientData.id)
                    sessionStorage.setItem('patientName', patientData.patient_info.name)
                    sessionStorage.setItem('status', patientData.state)
                    sessionStorage.setItem('serial_number', patientData.serial_number)
                    sessionStorage.setItem('department_id', patientData.department_id)
                    sessionStorage.setItem('delFlag', '1')
                    notification1.close()
                    router.push({
                      name: 'RealTimeMonitorZZ',
                      state: {
                        data:
                          JSON.stringify(patientData)
                      }
                    })
                    return
                  }

                }

              }
            })
            state.alertsShow.unshift(state.patienlist[i])
            state.alertsShowNew.unshift(state.patienlist[i])
            state.alertShowSave.unshift(state.patienlist[i])
          } else {
            
            
           
            const index2 = state.alertsShowNew.findIndex((item) => item.id == state.patienlist[i].id)
            if (index2 != -1) {
              state.alertsShowNew.splice(index2, 1);
            }
          }
        }

      }
      // 处理判断，数组里面没有就存进去，有就替换状态
      // state.alerts.push(alert)
      state.patienListOld.length = 0
    
      state.patienListOld.push(...state.alertsShow)
      // state.alertShowSave.length=0
      let str = JSON.stringify(state.alertShowSave)
      localStorage.setItem('alertShowSave', str)
    },
    // 移除报警
    REMOVE_ALERT(state, alert: any) {

    },
    clear_Alert(state, alery: any) {
      state.alertsShowNew.length = 0
    },
    RESET_ALL_STATES(state) {
      state.user = null
      state.patienlist = <any>[]
      state.patienListOld = <any>[]
      state.alertsShow = <any>[]
      state.alertsShowNew = <any>[]
      state.alertShowSave = <any>[]
    },
    GET_ALERT_SHOW(state) {
      state.alertShowSave.length = 0

      let str = localStorage.getItem('alertShowSave') || ''
      let arr = str ? JSON.parse(str) : []
      state.alertShowSave.push(...arr)
    }
  },
  actions: {
    reset({ commit }) {
      commit('RESET_ALL_STATES');
    },
    reget({ commit }) {
      commit('GET_ALERT_SHOW');
    }
  },
  modules: {
  },
  plugins: [createPersistedState({
    key: 'vuex',
    storage: window.sessionStorage, // 或者 localStorage
  })]
})
