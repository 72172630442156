<template>
    <div class="mains">
        <div>
            <el-menu :default-active="index" router>
                <el-menu-item-group v-for="item in menus[userType]" :key="item.index">
                    <el-menu-item :index="item.index" @click="toUrl(item)">
                        <span :class="item.icon"></span>
                        <span style="margin-left: 10px;">{{ item.name }}</span>

                    </el-menu-item>
                </el-menu-item-group>
            </el-menu>
        </div>

    </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup() {
        const menus = {
            admin: [
                {
                    name: '首页',
                    url: '/homePage',
                    index: '/homePage',
                    icon: 'icon-icon_menu_home'
                },
                {
                    name: '患者管理',
                    url: '/patientManage',
                    index: '/patientManage',
                    icon: 'icon-icon_menu_patient'
                },
                {
                    name: '设备管理',
                    url: '/EquipManage',
                    index: '/EquipManage',
                    icon: 'icon-icon_menu_equipment'
                },
                {
                    name: '账户管理',
                    url: '/DocManage',
                    index: '/DocManage',
                    icon: 'icon-icon_menu_account'
                },
                {
                    name: '报警记录',
                    url: '/ReleaseRecords',
                    index: '/ReleaseRecords',
                    icon: 'icon-icon_menu_alarm'


                },
                {
                    name: '设置',
                    url: '/AlarmSettings',
                    index: '/AlarmSettings',
                    icon: 'icon-icon_menu_set'
                },

            ],
            son:[
            {
                    name: '首页',
                    url: '/homePage',
                    index: '/homePage',
                    icon: 'icon-icon_menu_home'
                },
                {
                    name: '患者管理',
                    url: '/patientManage',
                    index: '/patientManage',
                    icon: 'icon-icon_menu_patient'
                },
                {
                    name: '设备管理',
                    url: '/EquipManage',
                    index: '/EquipManage',
                    icon: 'icon-icon_menu_equipment'
                },
                {
                    name: '报警记录',
                    url: '/ReleaseRecords',
                    index: '/ReleaseRecords',
                    icon: 'icon-icon_menu_alarm'


                },
                {
                    name: '设置',
                    url: '/AlarmSettings',
                    index: '/AlarmSettings',
                    icon: 'icon-icon_menu_set'
                },
            ]
        }
        const router = useRouter()
        console.log(router)
        const userType = sessionStorage.getItem('userType') || 'admin'
        let key = sessionStorage.getItem('rowindex')
        let index = ref('/homePage')
        if (router.currentRoute.value.fullPath == '/Home') {
            router.push('/homePage')
        }

        if (key) {
            index.value = key
        }
        function toUrl(e) {
            index.value = e.index
            sessionStorage.setItem('rowindex', e.index)
            sessionStorage.setItem('currIdx', e.url)
            sessionStorage.setItem('titlename', e.name)
            router.push(e.url)
        }
        return {
            menus,
            toUrl,
            userType,
            router,
            index
        }
    }
})
</script>

<style scoped>
.mains {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background: #fff;
}

.mains .el-menu {
    background: #fff;

}

.el-menu-item {
    font-size: 20px;
    font-family: 'SourceHanSansCN';
    font-weight: 400;
    background: #fff;
}

.el-menu-item.is-active {
    background: rgba(238, 124, 88, 0.1);
    border-radius: 0px;
    color: rgba(238, 124, 88, 1);
    font-weight: 400;
    font-family: 'SourceHanSansCN';
}



>>>.el-menu-item-group__title {
    padding: 0px;
}
</style>
<style>
.el-menu-item.is-active {
    .icon-icon_menu_home:before {
        color: rgba(238, 124, 88, 1) !important;
    }
    .icon-icon_menu_equipment:before {
        color: rgba(238, 124, 88, 1) !important;
    }
    .icon-icon_menu_account:before{
        color: rgba(238, 124, 88, 1) !important;
    }
    .icon-icon_menu_patient:before {
        color: rgba(238, 124, 88, 1) !important;
    }
    .icon-icon_menu_alarm:before{
        color: rgba(238, 124, 88, 1) !important;
    }
    .icon-icon_menu_set:before {
        color: rgba(238, 124, 88, 1) !important;
    }
}
</style>