import axios from 'axios'
import router from '../router/index'
import cookies from 'js-cookie'
import { ElMessage } from 'element-plus'

const request = axios.create({
    baseURL: '',  // 注意！！ 这里是全局统一加上了 '/api' 前缀，也就是说所有接口都会加上'/api'前缀在，页面里面写接口的时候就不要加 '/api'了，否则会出现2个'/api'，类似 '/api/api/user'这样的报错，切记！！！
    timeout: 100000
})
 
const ERRORMSG = {
    1: '参数错误',
    2: '用户不存在',
    3: '用户存在',
    4:'手机号不对',
    5:'邮箱不对',
    6:'邀请码错误',
    10: '用户类型错误',
    11: '没有登录',
    26:'验证码错误',
    1058:'手机号已注册',
    1065:'账号不存在',
    1064:'检测该账号已修改密码，请重新登录'
  }
// request 请求器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    if(config && config.headers){
        config.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    config.headers.set('Token', 'C0NNqtiOd2BWElrR8W4l8c2t5O1NaX0D2c7cgGfFAyYykYTtgh6KUJTwA8WBSBkd')
    const cookieUID = cookies.get('userid')
    let token  =sessionStorage.getItem('token')
    const sessionUID = sessionStorage.getItem('userAddId')
    if(cookieUID && sessionUID && cookieUID == sessionUID&&token){
       console.log('123')
    }else{
        console.log('123',router)
        router.push('/login')
       
    }
    // config.headers['token'] = user.token;  // 设置请求头
    return config
}, error => {
    return Promise.reject(error)
});
 
// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        let error =res.error
        if(error){
            if(error==11){
                ElMessage.closeAll();
                ElMessage.error(ERRORMSG[error])
                router.push('/login')
            }
            if(error==1065){
                ElMessage.closeAll();
                ElMessage.error(ERRORMSG[error])
                let val={
                    username:  sessionStorage.getItem('userAddId'),
                    password: sessionStorage.getItem('password'),
                }
                axios({
                    method: 'POST',
                    url: '/api/v2.1/center/logout',
                    data: val,
                    headers: {
                        'Token': 'C0NNqtiOd2BWElrR8W4l8c2t5O1NaX0D2c7cgGfFAyYykYTtgh6KUJTwA8WBSBkd'
                    }
                }).then((res:any)=>{
                    let error =res.data.error
                    if (error == 0) {
                        sessionStorage.clear();
                        router.push('/login')
                    }
                })
            }
            if(error==1064){
                ElMessage.closeAll();
                ElMessage.error(ERRORMSG[error])
                let val={
                    username:  sessionStorage.getItem('userAddId'),
                    password: sessionStorage.getItem('password'),
                }
                axios({
                    method: 'POST',
                    url: '/api/v2.1/center/logout',
                    data: val,
                    headers: {
                        'Token': 'C0NNqtiOd2BWElrR8W4l8c2t5O1NaX0D2c7cgGfFAyYykYTtgh6KUJTwA8WBSBkd'
                    }
                }).then((res:any)=>{
                    let error =res.data.error
                    if (error == 0) {
                        sessionStorage.clear();
                        router.push('/login')
                    }
                })
            }
            if(ERRORMSG[error]){
                ElMessage.closeAll();
                ElMessage.error(ERRORMSG[error])
            }
        }
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        // console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)
 
 
export default request